import React, {useEffect, useRef} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import styles from './Pagination.module.scss';
import Select from 'components/Select';
import TextField from 'components/TextField';

type PaginationProps = {
  basePath?: string;
  page: number;
  total: number;
  count: number;
  pageSize?: number;
  setCurrentPage: (page: number) => void;
  setPageSize?: (pageSize: number) => void;
};

const Pagination: React.VFC<PaginationProps> = ({
  page,
  count,
  total,
  basePath,
  pageSize = 40,
  setCurrentPage,
  setPageSize,
}) => {
  const {replace} = useHistory();

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = String(page);
    }
  }, [page]);

  if (!total) {
    return null;
  }

  const Link = basePath ? NavLink : 'div';

  return (
    <div className={styles.pagination}>
      <p>Items per page:</p>
      <Select
        menuPosition="fixed"
        onChange={(value) => setPageSize && setPageSize(Number(value?.value))}
        value={{label: pageSize.toString(), value: pageSize}}
        options={[
          {label: '20', value: 20},
          {label: '30', value: 30},
          {label: '40', value: 40},
          {label: '50', value: 50},
          {label: '60', value: 60},
          {label: '70', value: 70},
          {label: '80', value: 80},
          {label: '90', value: 90},
          {label: '100', value: 100},
        ]}
      />
      <p style={{marginRight: 70}}>
        {pageSize * (page - 1) + 1} - {Math.min(pageSize * page, count)} of{' '}
        {count} items
      </p>
      <Link
        style={{
          opacity: page === 1 ? 0.5 : 1,
        }}
        onClick={() => setCurrentPage(1)}
        to={`${basePath}/page/1`}
        className={styles.pagination__navlink}
      >
        |&lt;
      </Link>
      <Link
        style={{
          opacity: page === 1 ? 0.5 : 1,
        }}
        onClick={() => setCurrentPage(page === 1 ? 1 : page - 1)}
        to={`${basePath}/page/${page === 1 ? 1 : page - 1}`}
        className={styles.pagination__navlink}
      >
        &lt; Previous
      </Link>
      <div className={styles.pagination__list}>
        <TextField
          ref={inputRef}
          wrapperClass={styles.pagination__input_container}
          defaultValue={String(page)}
          onBlur={(event) => {
            setCurrentPage(Number(event.target.value));
            if (basePath) {
              replace(`${basePath}/page/${event.target.value}`);
            }
          }}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              //@ts-ignore
              const page = event.target.value;
              setCurrentPage(Number(page || 1));
              if (basePath) {
                replace(`${basePath}/page/${page || 1}`);
              }
            }
          }}
          className={styles.pagination__input}
        />
        <p>of {total}</p>
      </div>
      <Link
        style={{
          opacity: page === total ? 0.5 : 1,
        }}
        onClick={() => setCurrentPage(page === total ? total : page + 1)}
        to={`${basePath}/page/${page === total ? total : page + 1}`}
        className={styles.pagination__navlink}
      >
        Next &gt;
      </Link>
      <Link
        style={{
          opacity: page === total ? 0.5 : 1,
        }}
        onClick={() => setCurrentPage(total)}
        to={`${basePath}/page/${total}`}
        className={styles.pagination__navlink}
      >
        &gt;|
      </Link>
    </div>
  );
};

export default Pagination;
