import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Product} from 'types';
import styles from './ProductImage.module.scss';
import {ReactComponent as NoImageIcon} from 'assets/icons/no-image.svg';
import close from '../FileUpload/icons/close.svg';

type Props = {
  size?: 'thumbnail' | 'medium';
  product?: Product;
  value?: File;
  showDelete?: boolean;
  onChange?: (file: File | undefined) => void;
};

const ProductImage: React.VFC<Props> = ({
  size = 'thumbnail',
  product,
  value,
  onChange,
  showDelete = false,
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const isUploadable = useMemo(() => {
    return typeof onChange === 'function';
  }, [onChange]);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target && e.target.files) {
        setSelectedFile(e.target.files[0]);
      }
      onChange && e.target.files && onChange(e.target.files[0]);
    },
    [onChange]
  );

  useEffect(() => {
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setPreview(objectUrl);

      return () => {
        URL.revokeObjectURL(objectUrl);
      };
    }
  }, [selectedFile]);

  const deleteHandler = () => {
    setSelectedFile(undefined);
    setPreview(undefined);
    onChange && onChange(undefined);
  };

  const imageUrl = useMemo(() => {
    return preview || product?.image?.[size];
  }, [preview, product?.image, size]);

  return (
    <div style={{position: 'relative'}}>
      <label
        className={[
          styles.wrapper,
          styles[`wrapper--${size}`],
          isUploadable ? styles[`wrapper--uploadable`] : '',
        ].join(' ')}
      >
        {imageUrl ? (
          <img
            src={imageUrl}
            alt={product?.name}
            className={styles.product_image}
          />
        ) : (
          <NoImageIcon />
        )}
        {isUploadable && (
          <p className={styles.note}>Click to select new image</p>
        )}
        {isUploadable && (
          <input type="file" accept="image/*" onChange={handleInputChange} />
        )}
      </label>
      {showDelete && imageUrl && (
        <div onClick={deleteHandler} className={styles.close}>
          <img alt="close icon" src={close} />
        </div>
      )}
    </div>
  );
};

export default ProductImage;
