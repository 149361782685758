import {useQuery} from 'react-query';
import {ProductService} from 'services/product.service';
import {PaginatedResponse, Product} from 'types';

export type ProductTypeFilter = '' | 'otc' | 'prescription' | 'featured';

export type ProductFilter = {
  is_prescription?: Number;
  search?: string;
  page?: number;
  ordering?: string;
  productType?: ProductTypeFilter;
  pageSize?: number;
};

const useProductList = (filter: ProductFilter = {}) => {
  const {data, isLoading, refetch} = useQuery<PaginatedResponse<Product>>({
    queryFn: async () => {
      const productService = new ProductService();
      const response = await productService.getProducts(filter);

      return response.data;
    },
    queryKey: [
      'products',
      filter.is_prescription,
      filter.page,
      filter.search,
      filter.productType,
      filter.ordering,
      filter.pageSize,
    ],
  });

  return {
    refetch,
    products: data?.results || [],
    totalPages:
      data?.count && data.limit ? Math.ceil(data?.count / data.limit) : 0,
    isLoading,
    count: data?.count,
  };
};

export default useProductList;
