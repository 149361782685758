import api from 'api';
import {get} from 'lodash';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import toast from 'react-hot-toast';
import {useHistory} from 'react-router-dom';

interface FormValues {
  title: string;
  content: string;
  image: string;
  is_active: boolean;
}

function useFormComponent() {
  const {goBack} = useHistory();
  const {
    control,
    handleSubmit,
    setValue: setFieldValue,
    formState: {errors},
  } = useForm<FormValues>({
    defaultValues: {
      is_active: true,
    },
  });
  const [image, setImage] = useState<any>(null);
  const [loading, setLoading] = useState(false);

  const createHandler = (data: FormValues) => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', data.title);
    formData.append('slug', data.title.toLowerCase().replace(/ /g, '-'));
    formData.append('content', data.content);
    formData.append('image.file', image);
    formData.append('content_type', 'blog');
    formData.append('is_active', data.is_active ? 'true' : 'false');
    api
      .post('/support/blogs/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        goBack();
        setLoading(false);
      })
      .catch((err) => {
        toast.error(get(err, 'response.data.message', 'Something went wrong'));
        setLoading(false);
      });
  };

  return {
    image,
    setImage,
    control,
    errors,
    loading,
    createHandler: handleSubmit(createHandler),
    setFieldValue,
  };
}

export default useFormComponent;
