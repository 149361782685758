import {useEffect, useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {QueryFunctionContext, useQuery} from 'react-query';

import ViewDetails from 'components/ViewDetails';

import {ListResponse, User} from 'types';
import api from 'api';
import {getFullName} from 'helpers/user';

import localStyles from './Prescribers.module.scss';
import useSort from 'hooks/useSort';
import ReactSwitch from 'react-switch';
import toast from 'react-hot-toast';
import {get} from 'lodash';
import useDebounce from 'hooks/useDebounce';

async function getEmployees({queryKey}: QueryFunctionContext) {
  const [, role, order, keyWord, page, pageSize, status] = queryKey;

  const {data} = await api.get<ListResponse<User>>('/users/', {
    params: {
      role,
      order,
      search: keyWord ? keyWord : undefined,
      limit: pageSize,
      offset: +(page || 0) * Number(pageSize),
      prescriber_status: status,
    },
  });

  return data;
}

function useEmployees() {
  const {url} = useRouteMatch();

  const [pageSize, setPageSize] = useState(40);
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_employe`) || ''
  );
  const [status, setStatus] = useState(
    sessionStorage.getItem('presriber_tab') || 'all'
  );
  const [currentPage, setCurrentPage] = useState(1);
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const searchDebounced = useDebounce(keyWord, 400);

  const {data: employees, isLoading} = useQuery(
    [
      'prescribers',
      'prescriber',
      order,
      searchDebounced,
      currentPage - 1,
      pageSize,
      status,
    ],
    getEmployees
  );

  const data = useMemo(
    function () {
      if (!employees) return [];

      return employees.results
        .filter((employee) => employee.role !== 'customer')
        .map((employee) => {
          const phone = employee?.phone?.replace(/\D/g, '');
          return {
            ...employee,
            name: getFullName(employee),
            phone: phone
              ? '(' +
                phone.substring(0, 3) +
                ') ' +
                phone.substring(3, 6) +
                '-' +
                phone.substring(6, 10)
              : '',
            role: <span className={localStyles.role}>{employee.role}</span>,
            active:
              employee.role !== 'management' ? (
                <PrescribeStatus id={employee.id} active={employee.is_active} />
              ) : null,
            view: <ViewDetails to={`${url}/${employee.id}`} />,
          };
        });
    },
    [employees, url]
  );

  return {
    totalPages:
      employees?.count && employees.limit
        ? Math.ceil(employees.count / employees.limit)
        : 0,
    data,
    isLoading,
    sortBy,
    sortOrder,
    keyWord,
    setKeyWord,
    onSort,
    count: employees?.count,
    currentPage,
    setCurrentPage,
    status,
    setStatus,
    pageSize,
    setPageSize,
  };
}

const PrescribeStatus = ({active, id}: {active: boolean; id: number}) => {
  const [checked, setChecked] = useState(active);

  useEffect(() => {
    setChecked(active);
  }, [active]);

  const onChange = () => {
    setChecked((pr) => !pr);
    api
      .patch<User>(`/users/${id}/`, {
        is_active: !checked,
      })
      .then(() => {
        toast.success('Status updated successfully');
      })
      .catch((err) => {
        toast.error(
          get(err, 'response.data.message', '') || 'Failed to update status'
        );
        setChecked((pr) => !pr);
      });
  };

  return (
    <div
      style={{
        paddingRight: 60,
      }}
    >
      <label>
        <ReactSwitch
          onColor="#0072b3"
          onChange={onChange}
          handleDiameter={17}
          height={20}
          width={40}
          checked={checked}
        />
      </label>
    </div>
  );
};

export default useEmployees;
