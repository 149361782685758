import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useQuery, QueryFunctionContext} from 'react-query';

import {User} from 'types';

import api from 'api';
import useAuth from 'hooks/useAuth';
import {get} from 'lodash';

interface Option {
  label: string;
  value: string | number;
}

interface FormValue {
  organisation: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  address_line_1: string;
  city: string;
  zipcode: string;
  latitude: number;
  longitude: number;
  state: string;
  npi_no: string;
  username: string;
  password: string;
  password_confirmation: string;

  role: string;
  vehicle?: Option;
  is_active: boolean;
}

async function getEmployeeById({queryKey}: QueryFunctionContext) {
  const [, employeeId] = queryKey;

  if (!employeeId) return undefined;

  const {data} = await api.get<User>(`/users/${employeeId}`);

  return data;
}

function useEmployeeForm() {
  const {currentUser} = useAuth();
  const employeeId = currentUser?.id;
  const {push} = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const {
    handleSubmit,
    register,
    formState: {errors},
    reset,
    setValue,
    getValues,
  } = useForm<FormValue>();

  const {data: employee} = useQuery(['employee', employeeId], getEmployeeById);

  useEffect(
    function () {
      if (employee) {
        const user = employee;
        if (!user) {
          return;
        }

        reset({
          ...user,
          phone: user.phone
            ? '(' +
              user.phone.substring(0, 3) +
              ') ' +
              user.phone.substring(3, 6) +
              '-' +
              user.phone.substring(6, 10)
            : '',
          organisation: get(user, 'prescriber.organisation', ''),
          address_line_1: get(user, 'address.address_line_1', ''),
          city: get(user, 'address.city', ''),
          zipcode: get(user, 'address.zipcode', ''),
          latitude: get(user, 'address.latitude', 0),
          longitude: get(user, 'address.longitude', 0),
          state: get(user, 'address.state', ''),
        });
      }
    },
    [employee, reset]
  );

  async function submit(data: FormValue) {
    setSubmitting(true);

    const user = {
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      email: data.email,
      phone: data.phone.replace(/\D/g, ''),
      npi_no: data.npi_no,
      is_active: data.is_active,
      password: data.password,
      organisation: data.organisation,
      address: {
        address_line_1: data.address_line_1,
        city: data.city,
        zipcode: data.zipcode,
        latitude: data.latitude,
        longitude: data.longitude,
        state: data.state,
      },
    };

    try {
      await (employeeId
        ? api.patch<User>(`/users/${employeeId}/`, user)
        : api.post<User>('/users/', user));

      setSubmitting(false);

      push('prescriptions');
    } catch (error) {
      setSubmitting(false);
    }
  }

  return {
    employee,
    employeeId,
    submitting,
    errors,
    register,
    getValues,
    setValue,
    handleSubmit: handleSubmit(submit),
  };
}

export default useEmployeeForm;
