import DataTable from 'components/DataTable';
import ListWrapper from 'components/ListWrapper';
import Typography from 'components/Typography';
import {DataTableSchemaItem} from 'types';
import useBlogs from './useBlogs';
import Pagination from 'components/Pagination';
import Button from 'components/Button';
import styles from './blogs.module.scss';

const SCHEMA: DataTableSchemaItem[] = [
  {
    dataKey: 'title',
    header: 'Title',
  },
  {dataKey: 'image', header: 'Image'},
  {dataKey: 'created_at', header: 'Created date'},
  {
    dataKey: 'slug',
    header: 'Slug',
  },
  {
    dataKey: 'active',
    header: 'Published',
    align: 'right',
  },
  {dataKey: 'view', header: ''},
];

function Index() {
  const {
    data,
    isLoading,
    currentPage,
    setCurrentPage,
    totalPages,
    count,
    pageSize,
    setPageSize,
  } = useBlogs();

  return (
    <>
      <div className={styles.header}>
        <Typography variant="h2">Pages</Typography>
        <Button component="a" to="/website/pages/create" color="blue">
          Add Page
        </Button>
      </div>
      <ListWrapper>
        <DataTable
          schema={SCHEMA}
          data={data}
          loading={isLoading}
          // onClick={(row) => history.push(`/blogs/${row.slug}`)}
        />
        {totalPages > 1 && (
          <Pagination
            count={count}
            basePath="/pages"
            page={currentPage}
            total={totalPages}
            setCurrentPage={setCurrentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
        )}
      </ListWrapper>
    </>
  );
}

export default Index;
