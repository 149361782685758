import React from 'react';

import {MenuItemName} from 'types';

import IconBarcode from './IconBarcode';
import IconChat from './IconChat';
import IconCube from './IconCube';
import IconGear from './IconGear';
import IconHome from './IconHome';
import IconPackage from './IconPackage';
import Requests from './Requests';
import IconTruck from './IconTruck';
import IconUsers from './IconUsers';
import IconCart from './IconCart';
import IconBlog from './IconBlog';
import IconPages from './IconPages';
import IconTransaction from './IconTransaction';
import IconSite from './IconSite';

interface Props {
  className?: string;
  name: MenuItemName;
  color?: 'primary' | 'secondary';
}

const components: Record<MenuItemName, React.ReactElement> = {
  barcode: <IconBarcode />,
  transactions: <IconTransaction />,
  chat: <IconChat />,
  cube: <IconCube />,
  gear: <IconGear />,
  home: <IconHome />,
  package: <IconPackage />,
  rx: <IconCart />,
  prescriptions: <IconCube />,
  create_prescriptions: <IconCart />,
  requests: <Requests />,
  truck: <IconTruck />,
  users: <IconUsers />,
  pages: <IconBlog />,
  blog: <IconPages />,
  site: <IconSite />,
};

function MenuIcon({className, name}: Props) {
  const Icon = components[name];
  return <div className={className}>{Icon}</div>;
}

export default MenuIcon;
