import React, {useState} from 'react';
import {format} from 'date-fns';

import Avatar from 'components/Avatar';
import Button from 'components/Button';

import useCurrentUser from 'hooks/useCurrentUser';
import useNow from 'hooks/useNow';
import {getFullName} from 'helpers/user';

import clockIcon from './icons/clock.svg';

import styles from './NoteForm.module.scss';

interface Props {
  isPrescriber: boolean;
  source: string;
  onSubmit: (text: string, prescriber: boolean) => any;
}

function NoteForm({onSubmit, isPrescriber, source}: Props) {
  const [visiblePrescriber, setVisiblePrescriber] = useState(isPrescriber);
  const currentUser = useCurrentUser();
  const [text, setText] = useState('');
  const now = useNow();

  const handleChange: React.ChangeEventHandler<HTMLTextAreaElement> = (
    event
  ) => {
    setText(event.currentTarget.value);
  };

  const handleSubmit = async () => {
    onSubmit(text, visiblePrescriber);
    setVisiblePrescriber(isPrescriber);
    setText('');
  };

  return (
    <div className={styles.root}>
      <Avatar name={getFullName(currentUser)} file={currentUser?.avatar} />
      <div className={styles.content}>
        <textarea
          placeholder="Write a note"
          className={styles.textarea}
          value={text}
          onChange={handleChange}
        />
        <div className={styles.bottom}>
          <div className={styles.row}>
            <div className={styles.datetime}>
              <img className={styles.clock_icon} alt="clock" src={clockIcon} />
              {format(now, 'MMMM d, yyyy h:mm aa')}
            </div>
            {!isPrescriber && !source?.startsWith('app') && (
              <div className={styles.checkbox}>
                <input
                  onChange={() => setVisiblePrescriber(!visiblePrescriber)}
                  type="checkbox"
                />
                <p>Visible for prescriber</p>
              </div>
            )}
          </div>
          <Button size="lg" onClick={handleSubmit}>
            Post
          </Button>
        </div>
      </div>
    </div>
  );
}

export default NoteForm;
