import {Route, Switch, useRouteMatch, Redirect} from 'react-router-dom';
import Pages from '../Pages/PagesList';
import PagesForm from '../Pages/PagesForm';
import PagesDetails from '../Pages/PagesDetails';
import Blogs from '../Blogs/BlogsList';
import BlogsForm from '../Blogs/BlogsForm';
import BlogsDetails from '../Blogs/BlogDetails';
import {useEffect} from 'react';
import useUi from 'hooks/useUi';

function Index() {
  const {path} = useRouteMatch();
  const {setSidebarExtended, setFullsizeContent} = useUi();

  useEffect(
    function () {
      setSidebarExtended(false);
      setFullsizeContent(true);

      return function () {
        setSidebarExtended(true);
        setFullsizeContent(false);
      };
    },
    [setSidebarExtended, setFullsizeContent]
  );

  return (
    <Switch>
      <Route path={`${path}/pages`} exact component={Pages} />
      <Route path={`${path}/pages/create`} component={PagesForm} />
      <Route path={`${path}/pages/:slug`} component={PagesDetails} />
      <Route path={`${path}/blogs`} exact component={Blogs} />
      <Route path={`${path}/blogs/create`} component={BlogsForm} />
      <Route path={`${path}/blogs/:slug`} component={BlogsDetails} />
      <Redirect to={`${path}/blogs`} />
    </Switch>
  );
}

export default Index;
