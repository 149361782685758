import {useMemo, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {QueryFunctionContext, useQuery} from 'react-query';
import {SingleValue} from 'react-select';

import ViewDetails from 'components/ViewDetails';

import {ListResponse, SelectOption, User} from 'types';
import api from 'api';
import {getFullName} from 'helpers/user';
import roles from 'constants/roles';

import useSort from 'hooks/useSort';
import useDebounce from 'hooks/useDebounce';

const filterOptions: SelectOption[] = [
  {value: '', label: 'All employees'},
  ...roles,
];

async function getEmployees({queryKey}: QueryFunctionContext) {
  const [, role, order, keyWord] = queryKey;
  const {data} = await api.get<ListResponse<User>>('/users/', {
    params: {role, order, search: keyWord ? keyWord : undefined},
  });

  return data;
}

function useEmployees() {
  const {url} = useRouteMatch();
  const [keyWord, setKeyWord] = useState(
    sessionStorage.getItem(`search_employe`) || ''
  );
  const [filterValue, setFilterValue] = useState<SelectOption>(
    filterOptions[0]
  );
  const {sortBy, sortOrder, order, onSort} = useSort('name');

  const searchDebounced = useDebounce(keyWord, 400);

  const {data: employees, isLoading} = useQuery(
    ['employees', filterValue.value, order, searchDebounced],
    getEmployees
  );

  const data = useMemo(
    function () {
      if (!employees) return [];

      return employees.results
        .filter((employee) => employee.role !== 'customer')
        .map((employee) => {
          const phone = employee?.phone?.replace(/\D/g, '');
          return {
            ...employee,
            name: getFullName(employee),
            phone: phone
              ? '(' +
                phone.substring(0, 3) +
                ') ' +
                phone.substring(3, 6) +
                '-' +
                phone.substring(6, 10)
              : '',
            view: <ViewDetails to={`${url}/${employee.id}`} />,
          };
        });
    },
    [employees, url]
  );

  function onChangeFilter(newValue: SingleValue<SelectOption>) {
    newValue && setFilterValue(newValue);
  }

  return {
    data,
    isLoading,
    filterOptions,
    filterValue,
    sortBy,
    sortOrder,
    keyWord,
    setKeyWord,
    onChangeFilter,
    onSort,
  };
}

export default useEmployees;
