function IconBlog() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 9.36111V18C21 20.2091 19.2091 22 17 22H7C4.79086 22 3 20.2091 3 18V6C3 3.79086 4.79086 2 7 2H13.4464"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8 7H12"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8 12H16"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8 17H16"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M18 2V8"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M21 5L15 5"
        stroke="#222222"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
}

export default IconBlog;
