import {Fragment} from 'react';
import classNames from 'classnames';

import Checkbox from 'components/Checkbox';

import {ItemId, DataItem, DataTableSchemaItem} from 'types';

import styles from '../DataTable.module.scss';

interface Props {
  schema: DataTableSchemaItem[];
  data: DataItem[];
  selectable: boolean;
  selectedRows?: ItemId[];
  style: any;
  handleSelectSingle?: React.ChangeEventHandler<HTMLInputElement>;
  onClick?: (item: DataItem) => any;
}

function Data({
  schema,
  data,
  selectable,
  selectedRows,
  handleSelectSingle,
  onClick,
  style,
}: Props) {
  return (
    <Fragment>
      {data.map((row) => (
        <div
          key={`row-${row.id}`}
          onClick={() => onClick && onClick(row)}
          className={classNames(styles.row, styles.data_row)}
          style={style}
        >
          {selectable && (
            <div className={classNames(styles.select, styles.cell)}>
              <Checkbox
                className={styles.checkbox}
                checked={selectedRows?.includes(row.id)}
                onChange={handleSelectSingle}
                data-id={row.id}
                data-type={typeof row.id}
              />
            </div>
          )}
          {schema.map((s, index) => (
            <div
              key={`cell-${row.id}-${index}`}
              className={classNames(
                styles.cell,
                {
                  [styles[`--${s.align}`]]: s.align,
                },
                [styles[`${s.dataKey}`]]
              )}
            >
              {row[s.dataKey]}
            </div>
          ))}
          <div className={styles.border} />
        </div>
      ))}
    </Fragment>
  );
}

export default Data;
