import {useEffect, useMemo, useState} from 'react';
import {QueryFunctionContext, useQuery} from 'react-query';

import {ListResponse, Transaction} from 'types';
import useSort from 'hooks/useSort';
import api from 'api';
import {format, parseISO} from 'date-fns';
import {get} from 'lodash';

async function getRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [, page, status, creditType, date, key, startDate, endDate, pageSize] =
    queryKey;

  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        offset: +page * +pageSize,
        limit: +pageSize,
        transaction_origin: status ? status : undefined,
        service_name: creditType ? creditType.value : undefined,
        transaction_date: date,
        search: key ? key : undefined,
        transaction_date_after: startDate
          ? format(startDate, 'yyyy-MM-dd')
          : undefined,
        transaction_date_before: endDate
          ? format(endDate, 'yyyy-MM-dd')
          : undefined,
      },
    }
  );

  return data;
}

async function getRequestsGraph1() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'pos',
      },
    }
  );
  return data;
}
async function getRequestsGraph2() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'patient_app',
      },
    }
  );
  return data;
}
async function getRequestsGraph3() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'dashboard',
      },
    }
  );
  return data;
}
async function getRequestsGraph4() {
  const {data} = await api.get<ListResponse<Transaction>>(
    '/payment/transactions',
    {
      params: {
        limit: 1,
        transaction_origin: 'driver_app_cod',
      },
    }
  );
  return data;
}

const returnType = (type: string) => {
  if (type === 'patient_app') {
    return 'Patient App';
  }
  if (type === 'pos') {
    return 'POS';
  }
  if (type === 'dashboard') {
    return 'Dashboard';
  }
  if (type === 'driver_app_cod') {
    return 'Driver App COD';
  }
};

function transformData(data: Transaction[]) {
  return data.map((item) => ({
    ...item,
    id: item.transaction_id,
    transaction_origin: returnType(item.transaction_origin),
    transaction_date: format(
      parseISO(item.transaction_date),
      'MM/dd/yy EEE hh:mm a'
    ),
    amount: '$' + item.amount,
    list_rx: `${item.list_rx.slice(0, 3).join(', ')} ${
      item.list_rx.length > 3 ? '...' : ''
    }`,
    list_orders: `${get(item, 'list_orders', []).slice(0, 3).join(', ')} ${
      item?.list_orders?.length > 3 ? '...' : ''
    }`,
  }));
}

type RequestFilter = {
  userId?: string;
  source?: string;
  page?: number;
};

const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);

function useRequests(filter: RequestFilter) {
  const {sortBy, sortOrder, onSort} = useSort('id', 'desc');
  const [pageSize, setPageSize] = useState(40);
  const [source] = useState(filter.source || '');
  const [chartVisible, setChartVisible] = useState(false);
  const [status, setStatus] = useState(
    sessionStorage.getItem('state') === 'all'
      ? ''
      : sessionStorage.getItem('transactions_state') || ''
  );
  const [key, setKeyWord] = useState(
    sessionStorage.getItem(`searchKey_transaction`) || ''
  );
  const [apiKeyWord, setApiKeyWord] = useState(
    sessionStorage.getItem(`searchKey_transaction`) || ''
  );

  const [startDate, setStartDate] = useState(yesterday);
  const [endDate, setEndDate] = useState(new Date());

  const initialDate = sessionStorage.getItem('transactionDate')
    ? new Date(JSON.parse(sessionStorage.getItem('transactionDate') || ''))
    : undefined;

  const [date, setDate] = useState(initialDate);

  const [creditType, setCreditType] = useState<
    undefined | {value: string; label: string}
  >(
    sessionStorage.getItem('creditType')
      ? JSON.parse(sessionStorage.getItem('creditType') || '')
      : {label: 'Sale', value: 'CreditSale'}
  );

  const {data, isLoading} = useQuery(
    [
      'requests',
      String(filter.page || 0),
      status,
      creditType,
      date,
      apiKeyWord,
      startDate,
      endDate,
      String(pageSize),
    ],
    getRequests
  );

  const {data: graph1} = useQuery(['graph1'], getRequestsGraph1, {
    enabled: chartVisible,
  });
  const {data: graph2} = useQuery(['graph2'], getRequestsGraph2, {
    enabled: chartVisible,
  });
  const {data: graph3} = useQuery(['graph3'], getRequestsGraph3, {
    enabled: chartVisible,
  });
  const {data: graph4} = useQuery(['graph4'], getRequestsGraph4, {
    enabled: chartVisible,
  });

  const onChangeData = (val: any) => {
    const [start, end] = val;
    setStartDate(start);
    setEndDate(end);

    // setDate(val);
    // sessionStorage.setItem('transactionDate', val);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setApiKeyWord(key);
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [key]);

  const tabs = useMemo(function () {
    return [
      {title: 'All', value: ''},
      {title: 'POS', value: 'pos'},
      {title: 'Patient App', value: 'patient_app'},
      {title: 'Payment Link', value: 'payment_link'},
      {title: 'Dashboard', value: 'dashboard'},
      {title: 'Driver App COD', value: 'driver_app_cod'},
    ];
  }, []);

  return {
    data: data && !isLoading ? transformData(data.results) : [],
    count: data?.count || 0,
    totalPages:
      data?.count && data.limit ? Math.ceil(data.count / data.limit) : 0,
    dailyCount: data?.count || 0,
    totalAmount:
      (get(data, 'total_amount') === 'None'
        ? 0
        : get(data, 'total_amount', '0')) || 0,
    loading: isLoading,
    sortBy,
    sortOrder,
    tabs,
    status,
    keyWord: key,
    setKeyWord,
    onSort,
    setStatus,
    source,
    setDate,
    date,
    onChangeData,
    setCreditType,
    creditType,
    startDate,
    endDate,
    chartVisible,
    setChartVisible,
    chart: [
      {title: 'POS', value: graph1?.count || 0, color: '#E38627'},
      {title: 'Patient App', value: graph2?.count || 0, color: '#C13C37'},
      {title: 'Dashboard', value: graph3?.count || 0, color: '#6A2135'},
      {title: 'Driver App COD', value: graph4?.count || 0, color: '#0072b3'},
    ],
    pageSize,
    setPageSize,
  };
}

export default useRequests;
