import {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import Typography from 'components/Typography';
import {useQuery, QueryFunctionContext} from 'react-query';
import {format, parse, parseISO} from 'date-fns';
import {
  createAddress,
  Customer,
  ListResponse,
  Request,
  UserAddress,
} from 'types';
import api from 'api';
import states from 'constants/states';
import {CustomerService, UpdateCustomerDto} from 'services/customer.service';
import StatusBadge from 'components/StatusBadge/StatusBadge';
import ViewDetails from 'components/ViewDetails/ViewDetails';
import {
  mapMMStatusText,
  mapMMSubStatusColor,
  mapStatusColor,
  mapStatusText,
} from 'constants/mappings';
import {get} from 'lodash';
import useAuth from 'hooks/useAuth';
import toast from 'react-hot-toast';

interface Option {
  label: string;
  value: string | number;
}
interface FormValue {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  birthday: string;
  address_line_1: string;
  address_line_2: string;
  city: string;
  zipcode: string;
  state: Option;
  allergies: string;
}
interface PaginatedResponse<T> {
  count: number;
  limit: number;
  offset: number;
  results: T[];
}

async function getUserById({queryKey}: QueryFunctionContext) {
  const [, userId, isPrescriber] = queryKey;

  if (!userId || isPrescriber) return undefined;

  const {data} = await api.get<Customer>(`/customers/${userId}/`);

  return data;
}

async function getUserCards({queryKey}: QueryFunctionContext) {
  const [, userId, isPrescriber] = queryKey;

  if (!userId || isPrescriber) return undefined;

  const {data} = await api.get<any[]>(`/payment/method/?customer=${userId}`);

  return data;
}

async function getUserTransactions({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, page, isPrescriber, pageSize] = queryKey;

  if (!userId || isPrescriber) return undefined;

  const {data} = await api.get<PaginatedResponse<any>>(
    `/payment/transactions/`,
    {
      params: {
        offset: +page * Number(pageSize),
        limit: Number(pageSize),
        customer_id: userId,
      },
    }
  );

  return data;
}
async function getUserOrders({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, page, pageSize] = queryKey;

  if (!userId) return undefined;

  const {data} = await api.get<PaginatedResponse<any>>(`/requests/`, {
    params: {
      offset: +page * Number(pageSize),
      limit: Number(pageSize),
      source: 'micromerchant',
      customer_id: userId,
      order: '-id',
    },
  });

  return data;
}
async function getUserRequests({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, page, pageSize] = queryKey;

  if (!userId) return undefined;

  const {data} = await api.get<PaginatedResponse<any>>(`/requests/`, {
    params: {
      offset: +page * Number(pageSize),
      limit: Number(pageSize),
      source: 'app',
      customer_id: userId,
      order: '-id',
    },
  });

  return data;
}

async function getFilledOrders({queryKey}: QueryFunctionContext<any[]>) {
  const [, userId, isPrescriber] = queryKey;

  if (isPrescriber) {
    return undefined;
  }

  const {data} = await api.get<ListResponse<Request>>('/requests/', {
    params: {
      customer_id: userId,
      status: 'charge_all',
      source: 'micromerchant',
      limit: 100,
      is_paid: false,
    },
  });

  return data;
}

async function getStates({queryKey}: QueryFunctionContext<string[]>) {
  const [, keyWord, isPrescriber] = queryKey;

  if (isPrescriber) {
    return undefined;
  }

  const {data} = await api.get<PaginatedResponse<any>>(
    '/address-information/states/',
    {
      params: {
        limit: 40,
        search: keyWord,
      },
    }
  );
  return data;
}

async function getCities({queryKey}: QueryFunctionContext<string[]>) {
  const [, keyWordCity, isPrescriber] = queryKey;

  if (isPrescriber) {
    return undefined;
  }

  const {data} = await api.get<PaginatedResponse<any>>(
    '/address-information/cities/',
    {
      params: {
        limit: 20,
        search: keyWordCity,
      },
    }
  );
  return data;
}

function useUserForm(user_id?: number) {
  const {userId} = useParams<{userId: string}>();
  const {push, location} = useHistory();
  const [submitting, setSubmitting] = useState(false);
  const [keyWord, setKeyWord] = useState('');
  const [keyWordCity, setKeyWordCity] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(40);
  const [status, setStatus] = useState(
    sessionStorage.getItem('user_tab') || ''
  );
  const [addressesForUpdate, setAddressesForUpdate] = useState<UserAddress[]>(
    []
  );

  const [copayItems, setCopayItems] = useState<any[]>([]);

  const {currentUser} = useAuth();
  const isPrescriber = get(currentUser, 'role') === 'prescriber';

  const fetchable = location.pathname.startsWith('/settings/users');

  function transformData(data: Request[]) {
    return data.map((item) => ({
      ...item,
      sync_time: get(item, 'sync_date', '') + ' ' + item.sync_time,
      number: <Typography variant="strong">#M{item.id}</Typography>,
      is_paid: item.is_paid ? 'Yes' : 'No',
      rx_no: `${item.rx_no || ''}${
        item.refill_no ? ` (${item.refill_no})` : ''
      }`,
      status:
        get(item, 'request_origin', '') === 'micromerchant' ? (
          // @ts-ignore
          <StatusBadge
            /*// @ts-ignore*/
            color={mapMMSubStatusColor[get(item, 'status', '')]}
          >
            {/*// @ts-ignore*/}
            {mapMMStatusText[get(item, 'status', '')]}
          </StatusBadge>
        ) : (
          /*// @ts-ignore*/
          <StatusBadge color={mapStatusColor[get(item, 'status', '')]}>
            {/*// @ts-ignore*/}
            {mapStatusText[get(item, 'status', '')]}
          </StatusBadge>
        ),
      view: <ViewDetails to={`/requests/${item.id}`} />,
      checkbox: (
        <input
          defaultChecked={copayItems.find((i) => i.id === item.prescription_id)}
          onChange={(val) => {
            const include = copayItems.find(
              (i) => i.id === item.prescription_id
            );
            if (include) {
              setCopayItems(
                copayItems.filter((i) => i.id !== item.prescription_id)
              );
            } else {
              setCopayItems([
                ...copayItems,
                {...item, id: item.prescription_id},
              ]);
            }
          }}
          onClick={(val) => val.stopPropagation()}
          type="checkbox"
        />
      ),
    }));
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  const {
    handleSubmit,
    register,
    control,
    formState: {errors},
    reset,
    setValue,
  } = useForm<FormValue>();

  const {data: stateList} = useQuery(
    ['states', keyWord, isPrescriber ? 'yes' : ''],
    getStates,
    {
      enabled: fetchable,
    }
  );
  const {data: cityList} = useQuery(
    ['cities', keyWordCity, isPrescriber ? 'yes' : ''],
    getCities,
    {
      enabled: fetchable,
    }
  );
  const {
    data: customer,
    refetch,
    isLoading,
  } = useQuery(['user', userId, isPrescriber], getUserById);

  const {data: cards, refetch: refetchCards} = useQuery(
    ['card', userId || user_id, isPrescriber],
    getUserCards,
    {
      enabled: fetchable || location.pathname.startsWith('/orders'),
    }
  );
  const {data: transactions, isLoading: transactionsLoading} = useQuery(
    [
      'transactions',
      userId || user_id,
      currentPage - 1,
      isPrescriber,
      pageSize,
    ],
    getUserTransactions,
    {
      enabled: fetchable,
    }
  );
  const {data: orders, isLoading: orderLoading} = useQuery(
    ['user_orders', userId || user_id, currentPage - 1, pageSize],
    getUserOrders,
    {
      enabled: fetchable,
    }
  );

  const {data: requests, isLoading: requestsLoading} = useQuery(
    ['user_requests', userId || user_id, currentPage - 1, pageSize],
    getUserRequests,
    {
      enabled: fetchable,
    }
  );

  const {data: filledOrders, refetch: refetchFilledOrders} = useQuery(
    ['user_filled_orders', userId || user_id, isPrescriber],
    getFilledOrders,
    {
      enabled: fetchable,
    }
  );

  useEffect(() => {
    if (filledOrders?.results)
      setCopayItems(
        filledOrders?.results.map((item) => ({
          id: item.prescription_id,

          amount_due: item.amount_due,
        }))
      );
  }, [filledOrders]);

  useEffect(() => {
    if (customer?.user) {
      reset({
        ...customer,
        ...customer.user,
        phone: customer.user.phone
          ? '(' +
            customer.user.phone.substring(0, 3) +
            ') ' +
            customer.user.phone.substring(3, 6) +
            '-' +
            customer.user.phone.substring(6, 10)
          : '',
        birthday: customer.birthday
          ? format(
              parse(customer.birthday, 'yyyy-MM-dd', new Date()),
              'MM/dd/yyyy'
            )
          : '',
        state: states.find((state) => state.value === customer.user.state),
      });
    }
  }, [customer, reset]);

  async function createNewAddress(newAddress: createAddress) {
    const data = await api.post<any>(`/customers/address/`, {
      customer_id: Number(userId),
      ...newAddress,
    });
    if (data.status === 200) {
      await refetch();
    }
    return data;
  }

  async function createNewCard(newCard: any) {
    const data = await api.post<any>(`/payment/card/`, newCard);
    refetchCards();
    return data;
  }

  async function deleteAddress(addressId: number | undefined) {
    const {data} = await api.delete<Customer>(
      `/customers/address/${addressId}/`
    );

    refetch();

    return data;
  }

  async function deleteCard(cardId: number | undefined) {
    const {data} = await api.delete<Customer>(`/payment/method/${cardId}/`);
    refetchCards();
    return data;
  }

  async function submit(data: FormValue) {
    setSubmitting(true);

    const customerService = new CustomerService();

    if (customer) {
      const dto: UpdateCustomerDto = {
        ...data,
        phone: data.phone.replace(/\D/g, ''),
        birthday: format(
          parse(data.birthday, 'MM/dd/yyyy', new Date()),
          'yyyy-MM-dd'
        ),
        // avatar: null,
        addresses: addressesForUpdate,
      };

      await customerService.update(customer.id, customer.user.id, dto);
      toast.success('Customer updated successfully');
      // await api.patch(`/users/${userId}/`, dto);
    } else {
      const user = {
        ...data,
        phone: data.phone.replace(/\D/g, ''),
        // state: data.state.value,
        birthday: format(
          parse(data.birthday, 'MM/dd/yyyy', new Date()),
          'yyyy-MM-dd'
        ),
      };

      try {
        await api.post('/customers/', user);
      } catch (error) {
        setSubmitting(false);
      }
    }

    setSubmitting(false);
    if (!userId) {
      push(userId ? '/settings/users' : '/settings/users');
    }
    // push(userId ? `/settings/users/${userId}` : '/settings/users');
  }

  const returnType = (type: string) => {
    if (type === 'patient_app') {
      return 'Patient App';
    }
    if (type === 'pos') {
      return 'POS';
    }
    if (type === 'dashboard') {
      return 'Dashboard';
    }
    if (type === 'driver_app_cod') {
      return 'Driver App COD';
    }
  };

  const metas = {
    orders: {
      totalPages: Math.ceil((orders?.count || 0) / 20),
      count: orders?.count || 0,
    },
    requests: {
      totalPages: Math.ceil((requests?.count || 0) / 20),
      count: requests?.count || 0,
    },
    transactions: {
      totalPages: Math.ceil((transactions?.count || 0) / 20),
      count: transactions?.count || 0,
    },
  };

  return {
    userId,
    addresses: customer?.addresses || [],
    cards: cards || [],
    orders: orders?.results ? transformData(orders?.results) : [],
    requests: requests?.results ? transformData(requests?.results) : [],
    filledOrders: filledOrders?.results
      ? transformData(filledOrders?.results)
      : [],
    submitting,
    errors,
    control,
    register,
    reset,
    setValue,
    isLoading,
    orderLoading,
    requestsLoading,
    transactionsLoading,
    addressesForUpdate,
    setAddressesForUpdate,
    handleSubmit: handleSubmit(submit),
    createNewAddress,
    createNewCard,
    deleteAddress,
    deleteCard,
    setKeyWord,
    setKeyWordCity,
    stateList,
    cityList,
    refetch,
    transactions:
      transactions?.results.map((item) => ({
        ...item,
        id: item.transaction_id,
        amount: '$' + item.amount,
        transaction_origin: returnType(item.transaction_origin),
        transaction_date: format(
          parseISO(item.transaction_date),
          'MM/dd/yy EEE hh:mm a'
        ),
        list_rx: `${item.list_rx.slice(0, 3).join(', ')} ${
          item.list_rx.length > 3 ? '...' : ''
        }`,
        list_orders: `${get(item, 'list_orders', []).slice(0, 3).join(', ')} ${
          item?.list_orders?.length > 3 ? '...' : ''
        }`,
      })) || [],
    setCurrentPage,
    currentPage,
    pageSize,
    setPageSize,
    status,
    setStatus,
    totalPages: metas[status as keyof typeof metas]?.totalPages,
    count: metas[status as keyof typeof metas]?.count,
    isPrescriber,
    copayItems,
    refetchFilledOrders,
  };
}

export default useUserForm;
